<template>
  <div>
    <img :src="require(`../../../assets/icons/${selected ? 'icn_select.svg' : 'icn_unselect.svg'}`)" />
  </div>
</template>

<script>
export default {
  name: 'SelectedIndicator',
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>

</style>