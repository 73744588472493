<template>
  <div class="form-group" :style="cssVars">
    <div class="checkbox-wrapper">
      <div class="option-text">
      <h3 v-if="!!fieldTitle" class="form-input-title">{{ fieldTitle }}</h3>
        <span v-html="text"></span> <slot name="additional-text"></slot>
        
      </div>

      <label :for="`${fieldName}`" class="check-label-toggle">
        <input :id="`${fieldName}`"
              v-model="input"
              :name="`${fieldName}`"
              :value="value"
              type="checkbox"
              class="input-check-toggle"
              @change="emitValue(input)">
        <span class="check-mark-toggle"></span>
      </label>
    </div>


    <div v-show="apiError || errorMessages.length > 0">
      <!-- give priority to api error msg over vue validator error msg, until user inputs again -->
      <p v-if="apiError" class="text-error">{{ apiError }}</p>
      <p v-for="(error, index) of errorMessages" v-else :key="index" class="text-error">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
// import {ref, watch} from '@vue/composition-api';
import Validator from '@/utils/yo-validator/YoValidator'

export default {
  name: 'ToggleSwitch',
  props: {
    // required
    fieldTitle: {
      type: String,
      required: true
    },
    fieldName: {
      type: String,
      required: true
    },
    // not required
    rules: {
      type: String,
      required: false,
      default: ''
    },
    bindValue: {
      type: Boolean,
      required: false,
      default: true
    },
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    apiErrorText: {
      type: String,
      required: false,
      default: ''
    },
    callback: {
      type: Function,
      required: false,
      default: () => {}
    },
    text: {
      type: String,
      required: false,
      default: ''
    },
    checkmarkColor: {
      type: String,
      required: false,
      default: 'red'
    }
  },
  setup(props) {
    /** validator returned error messages **/
    const { errorMessages, setFieldData } = Validator.register(props)

    /** watch api error text from parent **/
    const apiError = ref('')
    watch(() => props.apiErrorText, (newVal) => {
      apiError.value = newVal.trim()
    })

    const cssVars = computed(() => { return { '--checkmark-color': props.checkmarkColor } })

    /** watch input in the template **/
    const input = ref(props.value)

    // set data to form wrapper after debounce
    const emitValue = (option) => {
      // if callback is passed, it means the radio is not a form value, it's just a helper to trigger another action
      if (props.callback) {
        props.callback(option)
      }
      // if the bind value is true, bind the value to the form data
      if (props.bindValue) {
        // set the value here since the v-model is fired sometimes after setFieldData
        setFieldData(option)
      }
    };

    watch(
      () => props.value,
      (newVal) => {
        if (props.isReadOnly || props.bindValue) {
          input.value = props.value;
          setFieldData(props.value);
        }
      }
    );

    /** on created **/
    (function setValueOnCreate() {
      if (props.value) {
        input.value = props.value
        setFieldData(props.value)
      }
    })()

    return {
      errorMessages,
      input,
      apiError,
      emitValue,
      cssVars
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.functions";
@import "~@/assets/css/base.mixins.scss";
@import "~@/assets/css/base.variables.scss";

.label-checkbox {
  margin-left: rem(15);
}

.check-label {
  margin: 0;
  padding: rem(5) 0 rem(5) rem(45);
  display: block;
  position: relative;
  cursor: pointer;
  text-align: left;
  @include user-select(none);

  &:checked {
    color: var(--pink_main);
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .check-mark {
      background-color: var(--pink_main);
      border: none;

      &:after {
        display: block;
      }
    }
  }

  .check-mark {
    @include position(absolute, $top: 0, $left: 0);
    @include border-radius(rem(8));
    height: rem(28);
    width: rem(28);
    background-color: white;
    box-shadow: rem(2) rem(2) 0 0 rgba(0, 0, 0, 0.1);

    &:after {
      @include position(absolute, $top: rem(3), $left: rem(10));
      @include rotate(45deg);
      content: "";
      display: none;
      border: solid white;
      border-width: 0 rem(2) rem(2) 0;
      height: rem(16);
      width: rem(9);
    }
  }

  .check-mark-error {
    @extend .check-mark;
  }
}

.check-label-round {
  @extend .check-label;

  .check-mark {
    @include border-radius(50%);
  }
}

.input-check{
  width: rem(0);
}


.check-label-toggle {
  position: relative;
  display: inline-block;
  width: rem(48);
  height: rem(28);
}

.check-label-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.check-mark-toggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #8287A9;
  -webkit-transition: .4s;
  transition: .4s;
}

.check-mark-toggle:before {
  position: absolute;
  content: "";
  height: rem(24);
  width: rem(24);
  // left: 4px;
  // bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  box-shadow: 0 rem(1) rem(3) 0 rgba(0,0,0,0.5);
  top: rem(2);
  left: rem(2);
}

input:checked + .check-mark-toggle {
  background-color: var(--pink_main);
}

input:focus + .check-mark-toggle {
  box-shadow: 0 0 1px var(--pink_main);;
}

input:checked + .check-mark-toggle:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.check-mark-toggle {
  border-radius: 34px;
}

.check-mark-toggle:before {
  border-radius: 50%;
}

.checkbox-wrapper {
  display: flex;
}

.option-text {
  width: rem(324);
  color: var(--primary-dark);
  font-size: rem(14);
  letter-spacing: 0;
  line-height: rem(24);
  margin-right: rem(78);
  padding-top: rem(5);
}
</style>
