<template>
  <div class="info-tooltip-wrapper">

    <img src="../../assets/icons/icn_info.svg" class="toggler" alt="Info icon"/>

    <!-- Tooltip -->
    <div class="tooltip">
      <p class="text">{{ text }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InfoTooltip',
  props: {
    text: {
      type: String,
      required: true
    }
  },
  setup() {

  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/base.variables";
@import "../../assets/css/base.mixins";

.info-tooltip-wrapper {
  position: relative;
  cursor: pointer;

  .toggler {
    //@include hover-active-pointer();
    height: rem(20);
    width: rem(20);
  }

  .tooltip {
    @include position(absolute, $top: 50%, $right: calc(100% + 16px), $left: unset);
    background-color: white;
    border-radius: rem(10);
    box-shadow: 0 0 rem(2) rem(2) rgba(0,0,0,0.1);
    display: none;
    padding: rem(16);
    text-align: left;
    transform: translateY(-50%);
    width: rem(390);

    .text {
      color: var(--blue_dark_01);
      font-size: rem(16);
      letter-spacing: 0;
      line-height: rem(24);
    }

    &:after {
      @include position(absolute, $top: 50%, $right: rem(-8));
      content: " ";
      border-left: rem(8) solid white;
      border-bottom: rem(8) solid transparent;
      border-top: rem(8) solid transparent;
      transform: translateY(-50%);
      height: 0;
      width: 0;
    }
  }

  &:hover {
    .tooltip {
      display: block;
    }
  }
}

@media screen and (min-width: $screen-xl) {
  .info-tooltip-wrapper {
    .tooltip {
      @include position(absolute, $top: 50%, $left: calc(100% + 16px));
      box-shadow: rem(2) rem(2) 0 0 rgba(0,0,0,0.1);
      width: rem(390);

      &:after {
        border-left: unset;
        border-bottom: rem(8) solid transparent;
        border-right: rem(8) solid white;
        right: unset;
        left: rem(-8);
      }
    }
  }
}
</style>
